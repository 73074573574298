import styled from 'styled-components';

const PriceText = styled.div`
  margin-top: 4px;
  text-align: center;
  font-weight: 300;
  font-size: 10px;
`;

export default PriceText;
