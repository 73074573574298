import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Icon from './components/Icon';
import DateText from './components/DateText';
import PriceContainer from './components/PriceContainer';
import DataContainer from './components/DataContainer';
import Data from './components/Data';
import Label, {Price, PriceLabel, PriceText} from './components/Label';

// consts

//lib
import ediErrorCodeHandler from '../../lib/ediErrorCodeHandler';

// translations
import {withTranslation} from 'react-i18next';
import {formatPriceWithCurrency} from '../../lib/formatPriceWithCurrency';

class ExtendedTableRow extends Component {
  state = {
    showNetPrice: false,
  };
  static propTypes = {
    netPrice: PropTypes.number,
    inquiryError: PropTypes.string,
    discountGrossPrice: PropTypes.number,
    availabilityDate: PropTypes.string,
    ean: PropTypes.string,
    load: PropTypes.string,
    depth: PropTypes.number,
  };

  downloadDocument = (url) => {
    window.open(url, '_blank');
  };

  showNetPriceClick = () => {
    this.setState({
      showNetPrice: !this.state.showNetPrice,
    });
  };

  render() {
    const {
      availabilityDate,
      inquiryError,
      netPrice,
      discountGrossPrice,
      ean,
      depth,
    } = this.props;
    const showNetPrice = this.state.showNetPrice;
    const t = this.props.t;

    return (
      <Container>
        <DataContainer>
          <Label>{t('estimatedDelivery')}</Label>
          <div>
            <Icon
              valid={availabilityDate}
              className={`mdi ${
                availabilityDate === undefined
                  ? 'mdi-loading mdi-spin'
                  : availabilityDate === null
                  ? 'mdi-close'
                  : 'mdi-check'
              }`}
            />
            <DateText valid={availabilityDate}>
              {showDeliveryDate(inquiryError, availabilityDate, t)}
            </DateText>
          </div>
        </DataContainer>
        <DataContainer>
          <Label>{t('ean')}</Label>
          <Data>{ean}</Data>
        </DataContainer>
        <DataContainer>
          <Label>{t('profileDepth')}</Label>
          <Data>{depth}</Data>
        </DataContainer>
        <PriceContainer>
          <PriceLabel>
            {t('actionPrice')}:{' '}
            <Price>{formatPriceWithCurrency(discountGrossPrice)}</Price>
            <PriceText>{t('actionPriceText')}</PriceText>
          </PriceLabel>
          {showNetPrice ? (
            <PriceLabel clickable onClick={() => this.showNetPriceClick()}>
              {t('purchasePrice')}:{' '}
              <Price>{formatPriceWithCurrency(netPrice)}</Price>
              <PriceText>{t('purchasePriceText')}</PriceText>
            </PriceLabel>
          ) : (
            <PriceLabel clickable onClick={() => this.showNetPriceClick()}>
              {t('otherData')}
            </PriceLabel>
          )}
        </PriceContainer>
      </Container>
    );
  }
}
const showDeliveryDate = (inquiryError, deliveryDate, t) => {
  const error = ediErrorCodeHandler(inquiryError, t);
  if (error === null) {
    return deliveryDate === null ? t('notInStock') : deliveryDate;
  } else {
    return error;
  }
};

export default withTranslation()(ExtendedTableRow);
